<div class="edge-portal-aside-container {{statusClass}}" (mouseleave)="unhover()">
    <div class="edge-portal-aside-panel">
        <div class="edge-portal-aside-brand-area">
            <section class="edge-portal-aside-brand-container">
                <a href="https://www.databank.com/" target="blank" class="logo-white"></a>
                <a href="javascript:void(0)" class="edge-portal-aside-toggler" (click)="toggleStatus()">
                    <mat-icon svgIcon="double_arrow_left" class="toggle-collapsing"></mat-icon>
                    <mat-icon svgIcon="double_arrow_right" class="toggle-expanding"></mat-icon>
                </a>
            </section>
        </div>
        <div class="edge-aside-menu">
            <ul id="side-bar" class="edge-aside-menu-container" [loading]="isLoading" [no-label]="true"
                [no-background]="true" (mouseenter)="hover()">
                <li class="edge-aside-menu-item {{item.isActive?'active':''}}" *ngFor="let item of menu;index as i">
                    <ng-container *ngIf="item.children.length; else noChildren">
                        <a href="javascript:void(0)"
                            class="edge-aside-menu-link parent {{item.expanded ? '' : 'collapsed'}}"
                            (click)="onClick(i)">
                            <mat-icon svgIcon="{{item.icon}}" class="edge-aside-menu-icon"></mat-icon>
                            <span class="edge-aside-menu-text flexible">{{item.name}}</span>
                            <span class="edge-aside-menu-toggle" (click)="$event.stopPropagation();toggle(i)">
                                <mat-icon svgIcon="collapse_arrow" class="toggle-collapsing"></mat-icon>
                                <mat-icon svgIcon="expand_arrow" class="toggle-expanding"></mat-icon>
                            </span>
                        </a>
                        <ul class="edge-aside-submenu">
                            <li class="edge-aside-submenu-item" *ngFor="let child of item.children;index as ci">
                                <a href="javascript:void(0)"
                                    class="edge-aside-submenu-link {{child.isActive?'active':''}}"
                                    (click)="onClick(i, ci)">
                                    <span class="edge-aside-submenu-icon">•</span>
                                    <span class="edge-aside-submenu-text flexible">{{child.name}}</span>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-template #noChildren>
                        <a href="javascript:void(0)" class="edge-aside-menu-link" (click)="onClick(i)">
                            <mat-icon svgIcon="{{item.icon}}" class="edge-aside-menu-icon"></mat-icon>
                            <span class="edge-aside-menu-text flexible">
                                {{item.name}}
                            </span>
                        </a>
                    </ng-template>
                </li>

                <li class="edge-portal-aside-contact-item">
                    <span class="edge-portal-aside-contact-label">Personal Support</span>
                    <a class="edge-portal-aside-link" href="tel:18553282247">1.855.328.2247</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<div class="edge-portal-aside-brick {{statusClass}}"></div>