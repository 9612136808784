export const PERMISSIONS = {
    HeaderTickets: 0,
    HeaderAlarms: 1,
    HomePage: 2,
    HomePagePageBodyOrganizationAnnouncements: 3,
    HomePagePageBodyActiveTickets: 4,
    HomePagePageBodyActiveAlarms: 5,
    HomePagePageBodyAttacksBlocked: 6,
    HomePagePageBodyDataProtected: 7,
    HomePagePageBodyBandwidthValueAdd: 8,
    HomePagePageBodyFirewalls: 9,
    HomePagePageBodyDeviceAlarmsServers: 10,
    HomePagePageBodyDeviceAlarmsFirewalls: 11,
    HomePagePageBodyDeviceAlarmsStorageNasSan: 12,
    HomePagePageBodyDeviceAlarmsVips: 13,
    HomePagePageBodyHealthChecks: 14,
    HomePagePageBodyRecentActivityFinanceEvents: 15,
    HomePagePageBodyRecentActivityTicketEvents: 16,
    HomePagePageBodyRecentActivityServerEvents: 17,
    HomePagePageBodyRecentActivityLoadBalancerEvents: 18,
    HomePagePageBodyRecentActivitySSLEvents: 19,
    HomePagePageBodyRecentActivityUserEvents: 20,
    HomePagePageBodyRecentActivityDatacenterAccessEvents: 21,
    HomePagePageBodyAlarmHistory: 22,
    HomePagePageBodyLoadBalancers: 23,
    HomePagePageBodyLoadBalancerNodes: 24,
    HomePagePageBodyOpenTickets: 25,
    HomePagePageBodyAlarmingPoolMember: 26,
    HomePagePageBodyIpsIds: 27,
    HomePagePageBodyLast5SecurityEvents: 28,
    HomePagePageBodyBandwidth: 29,
    HomePagePageBodyEdgeTeam: 30,
    HomePagePageBodyColocationInterconnects: 31,
    DashboardPage: 32,
    DashboardActiveTickets: 33,
    DashboardActiveAlarms: 34,
    DashboardAttacksBlocked: 35,
    DashboardDataProtected: 36,
    DashboardFirewalls: 37,
    DashboardDeviceAlarms: 38,
    DashboardLoadBalancers: 39,
    DashboardHealthChecks: 40,
    DashboardOpenTickets: 41,
    DashboardBandwidth: 42,
    DashboardEdgeTeam: 43,
    DashboardDeviceAlarmsServers: 44,
    DashboardDeviceAlarmsFirewalls: 45,
    DashboardDeviceAlarmsStorageNasSan: 46,
    DashboardDeviceAlarmsVIPS: 47,
    AlarmsPage: 48,
    AlarmsPageAlarms: 49,
    SystemsPage: 50,
    SystemsPageServers: 51,
    SystemsPageFirewalls: 52,
    SystemsPageVIPs: 53,
    SystemsPageStorage: 54,
    SystemsPageVPC: 55,
    SystemsPageAWSManagementConsole: 56,
    SystemsPageELBWidget: 57,
    SystemsPageLicensing: 58,
    SystemsPageVirtualPrivateCloud: 59,
    SystemsPageDisasterRecovery: 60,
    SystemDetailsPageServers: 61,
    SystemDetailsPageServersActiveAlarms: 62,
    SystemDetailsPageServersPerformance: 63,
    SystemDetailsPageServersSystemDetails: 64,
    SystemDetailsPageServersStatus: 65,
    SystemDetailsPageServersStorageNasSan: 66,
    SystemDetailsPageServersAlarmHistory: 67,
    SystemDetailsPageServersServicesLicenses: 68,
    SystemDetailsPageServersIPAddresses: 69,
    SystemDetailsPageServersFilesSystemBackups: 70,
    SystemDetailsPageServersDatabaseBackups: 71,
    SystemDetailsPageServersRestoreHistory: 72,
    SystemDetailsPageServersRestoreFilesFolders: 73,
    SystemDetailsPageServersPatchingSchedule: 74,
    SystemDetailsPageServersServerEvents: 75,
    SystemDetailsPageServersSnapshot: 76,
    SystemDetailsPageServersDeviceMonitors: 77,
    SystemDetailsPageFirewalls: 78,
    SystemDetailsPageFirewallsPerformance: 79,
    SystemDetailsPageFirewallsSystemdetails: 80,
    SystemDetailsPageFirewallsInterfaces: 81,
    SystemDetailsPageFirewallsIPAddresses: 82,
    SystemsDetailsPageFirewallsConnections: 83,
    SystemDetailsPageFirewallsSyslogs: 84,
    SystemDetailsPageFirewallsACLs: 85,
    SystemDetailsPageFirewallsVPNTunnels: 86,
    SystemDetailsPageFirewallsFirewallevents: 87,
    SystemDetailsPageFirewallsVPNUsers: 88,
    SystemDetailsPageFirewallsNATs: 89,
    SystemDetailsPageFirewallsDeviceMonitors: 90,
    SystemDetailsPageStorageNas: 91,
    SystemDetailsPageStorageNasVolumeIOPS: 92,
    SystemDetailsPageStorageNasVolumeUsage: 93,
    SystemDetailsPageStorageServicesLicenses: 94,
    SystemDetailsPageStorageIPAddresses: 95,
    SystemDetailsPageStorageNasSystemDetails: 96,
    SystemDetailsPageStorageNasDeviceMonitors: 97,
    SystemDetailsPageStorageSan: 98,
    SystemDetailsPageStorageSanVolumeIOPS: 99,
    SystemDetailsPageStorageSanVolumeUsage: 100,
    SystemDetailsPageStorageSanSystemDetails: 101,
    SystemDetailsPageStorageSanDeviceMonitors: 102,
    SystemsDetailsPageVPC: 103,
    SystemDetailsPageSystemDetails: 104,
    SystemDetailsPageSubnet: 105,
    SystemDetailsPageElasticIPAddress: 106,
    SystemDetailsPageNetworkACLs: 107,
    ElasticLoadBalancerELBPage: 108,
    SystemsDetailsPageELB: 109,
    SystemDetailsPageELBSystemDetailsWidget: 110,
    SystemDetailsPageInstancesWidget: 111,
    SystemDetailsPageListenersWidget: 112,
    SystemDetailsPageHealthChecksWidget: 113,
    SystemDetailsPageSecurityGroupsWidget: 114,
    SecurityPage: 115,
    SecurityPageAttackTrend: 116,
    SecurityPageReputation: 117,
    SecurityPageAppAttacks: 118,
    SecurityPageGEOSource: 119,
    SecurityPageSourceIP: 120,
    SecurityPageTargetIP: 121,
    SecurityPageAttackMap: 122,
    SecurityPageAttackTrendGraph: 123,
    SecurityPageTop5attacksdests: 124,
    SecurityPageTop10AppGeo: 125,
    SecurityPageTop10Applications: 126,
    SecurityPageAttacksBySeverity: 127,
    SecurityPageTopAttackSources: 128,
    SecurityPageIpsIdsAttackTrend: 129,
    SecurityPageAttacksByType: 130,
    SecurityPageTopDestinationPorts: 131,
    SecurityPageQuarantineLookup: 132,
    CompliancePage: 133,
    CompliancePageLastVulnerabilityScan: 134,
    CompliancePageServersMissingCriticalPatches: 135,
    CompliancePageDuoAuthorizedUsers: 136,
    CompliancePageServerLogsEvents: 137,
    CompliancePageChangedFiles: 138,
    CompliancePageDuoAuthenticatedLogs: 139,
    CompliancePageDuoApplications: 140,
    CompliancePageTripwirePCIASVScans: 141,
    CompliancePageFileIntegrityMonitoring: 142,
    CompliancePageLogCenter: 143,
    CompliancePageScans: 144,
    CompliancePageAgents: 145,
    CompliancePageWebApplicationScans: 146,
    CompliancePageAgentsInfo: 147,
    CompliancePageWebApplicationScansInfo: 148,
    CompliancePageInternalVulnerabilityScans: 149,
    CompliancePageExternalVulnerabilityScans: 150,
    DataBankTrustCenterPage: 151,
    ServicesPage: 152,
    ServicesPageExpiringSSLCertificatesTopWidget: 153,
    ServicesPageSSLCertificates: 154,
    SSLOrderDetailsPage: 155,
    ServicesPageCloudFlare: 156,
    CloudFlareDetailsPage: 157,
    SupportPage: 158,
    SupportPageTicketHistory: 159,
    VideoTutorial: 160,
    AddNewTicket: 161,
    SupportTicketDetails: 162,
    ReserveConferenceRoomPage: 163,
    NewDeliveryTicketPage: 164,
    RequestRemoteHandsPage: 165,
    RequestDisconnect: 166,
    NewBillingInquiry: 167,
    LoadBalancerPage: 168,
    LoadBalancerDetailsPage: 169,
    Analytics: 170,
    AccountManagement: 171,
    AccountManagementDashboardAlarms: 172,
    AccountManagementDashboardEvents: 173,
    AccountManagementDashboardBackups: 174,
    AccountManagementDashboardRestores: 175,
    AccountManagementDashboardTicketsByPerson: 176,
    AccountManagementDashboardTicketsByPriority: 177,
    AccountManagementDashboardTicketsByCategory: 178,
    MonitoringDashboard: 179,
    MyAccountAccountOverviewPage: 180,
    MyAccountAccountInformation: 181,
    MyAccountInvoiceHistory: 182,
    MyAccountTransactionHistory: 183,
    MyAccountTransactionHistoryDetailsPage: 184,
    MyAccountPaymentInformation: 185,
    MyAccountBillingNotifications: 186,
    MyAccountManageUsers: 187,
    ManageUsers: 188,
    MyAccountManageRoles: 189,
    ManageRoles: 190,
    MyAccountAuthorizedSigners: 191,
    AuthorizedSigners: 192,
    MyAccountManageTags: 193,
    ManageTags: 194,
    MyProfilePage: 195,
    MyProfilePagePersonalInformation: 196,
    MyProfileCurrentSessions: 197,
    MyProfilePageRole: 198,
    MyProfilePageDefaultOrganization: 199,
    MyProfilePageTags: 200,
    MyProfilePageDuoTwoFactorAuthentication: 201,
    MyProfilePageEnrolledDevices: 202,
    MyProfilePageNotifications: 203,
    FeedbackForm: 204,
    QuoteDetailsExecuteOnlineOrder: 205,
    Help: 206,
    ManageAds: 207,
    KnowledgeBase: 208,
    MonitorsPage: 209,
    DataCenterPhysicalAccessPage: 210,
    DataCenterPhysicalAccessPageCardholderAccessList: 211,
    DataCenterPhysicalAccessPageDatacenterAccessReport: 212,
    ColocationPage: 213,
    ColocationPower: 214,
    ColocationEnvironmental: 215,
    ColocationSpace: 216,
    ColocationAssets: 217,
    ColocationInterconnectPage: 218,
    NetworkDataPage: 219,
    NetworkDataIPPrefixes: 220,
    NetworkDataTop10ProtocolsAndPorts: 221,
    NetworkDataProtocolDistribution: 222,
    NetworkDataTrafficFlowbyIPAndProtocol: 223,
    NetworkDataThreatList: 224,
    NetworkDataBandwidthUsage: 225,
    LiveStatusPage: 226,
    LiveStatusLiveSystemStatus: 227,
    LiveStatusNotices: 228,
    LiveStatusDetailsPage: 229,
    DataProtectedPage: 230,
    DataProtectedBackups: 231,
};

export const PERMISSIONS_ACTIONS = {
    none: 0,
    view: 1,
    add: 2,
    edit: 4,
    delete: 8,
};
