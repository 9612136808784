import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({ name: "edgeTime" })
export class EdgeTimePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: string | number | Date, args?: string): string {
        if(!!value && value !== "null null")
            return this.datePipe.transform(value, "hh:mm a", args);
    }
}
