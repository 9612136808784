import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

import { META_DATA } from "@shared/metadata/organization.metadata";

@Pipe({ name: "edgeDate" })
export class EdgeDatePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: string | number | Date, culture?: string, timezone?: string): string {
        if (value === "null null")
            return

        // In some places we can pass null or empty string for 'culture'
        // For such cases we should use default culture "en-US"
        if (!culture) culture = "en-US";

        const dateFormat = META_DATA.cultureData
            .find(({ currentCulture }) => currentCulture === culture)
            .dateFormat;

        return this.datePipe.transform(value, dateFormat, timezone);
    }
}
