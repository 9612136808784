export enum IconsEnum {
    dashboard = "dashboard",
    how_to_reg = "how_to_reg",
    add = "add",
    expand = "expand",
    sync = "sync",
    refresh = "refresh",
    navigate_next = "navigate_next",
    navigate_previous = "navigate_previous",
    navigate_last = "navigate_last",
    navigate_first = "navigate_first",
    double_arrow = "double_arrow",
    file_excel = "file_excel",
    file_pdf = "file_pdf",
    file_csv = "file_csv",
    file_xml = "file_xml",
    file_xlsx = "file_xlsx",
    warning = "warning",
    warning_outlined = "warning_outlined",
    error = "error",
    error_outlined = "error_outlined",
    info = "info",
    success = "success",
    options = "options",
    edit = "edit",
    email = "email",
    person = "person",
    file = "file",
    search = "search",
    circle = "circle",
    video = "video",
    logout = "logout",
    clear = "clear",
    attach = "attach",
    replay = "replay",
    cancel = "cancel",
    clock = "clock",
    more = "more",
    shield = "shield",
    tags = "tags",
    checklist = "checklist",
    list_bulleted = "list_bulleted",
    phone = "phone",
    smartphone = "smartphone",
    tablet = "tablet",
    tablet_alt = "tablet_alt",
    trash = "trash",
    arrow_from_right = "arrow_from_right",
    star = "star",
    insert_drive_file = "insert_drive_file",
    pay = "pay",
    cancel_filled = "cancel_filled",
    success_filled = "success_filled",
    history = "history",
    rounded_storage = "rounded_storage",
    rounded_equalizer = "rounded_equalizer",
    rounded_apartment = "rounded_apartment",
    speed = "speed",
    bolt = "bolt",
    line_chart = "line_chart",
    email_outline = "email_outline",
    windows = "brands/windows",
    ubuntu = "brands/ubuntu",
    centos = "brands/centos",
    "redhat-logo" = "brands/redhat",
    cloud = "cloud",
    location = "location",
    terminal = "terminal",
    arrow_drop_down_circle = "arrow_drop_down_circle",
    help_outline = "help_outline",
    restore = "restore",
    folder = "folder",
    sync_alt = "sync_alt",
    settings_backup_restore = "settings_backup_restore",
    link_off = "link_off",
    lock_reset = "lock_reset",
    chart_bar = "chart_bar",
    preview = "preview",
    eraser = "eraser",
    cogs = "cogs",
    copy = "copy",
    visibility = "visibility",
    close = "close",
    fact_check = "fact_check",
    zoom_in = "zoom_in",
    cloud_upload = "cloud_upload",
    task_filled = "task_filled",
    note_add_filled = "note_add_filled",
    apple = "brands/apple",
    blackberry = "brands/blackberry",
    windows_alt = "brands/windows_alt",
    android = "brands/android",
    lock = "lock",
    lock_shield = "lock_shield",
    earth = "earth",
    target = "target",
    star_filled = "star_filled",
    place = "place",
    geo = "geo",
    chart = "chart",
    bar_down = "bar_down",
    bar_straight = "bar_straight",
    bar_up = "bar_up",
    power = "power",
    thumb_up = "thumb_up",
    thumb_down = "thumb_down",
    double_arrow_left = "keyboard_double_arrow_left",
    double_arrow_right = "keyboard_double_arrow_right",
    collapse_arrow = "expand_more",
    expand_arrow = "chevron_right",
    home = "home",
    heartbeat = "monitor_heart",
    tachometer = "network_check",
    cable = "cable",
    lan = "lan",
    tree = "tree",
    tree_alt = "tree_alt",
    door_open = "door_open",
    diagnostics = "diagnostics",
    outlet = "outlet",
    group_chat = "group_chat",
    clipboard_check = "clipboard_check",
    shield_protected = "shield_protected",
    chart_pie = "chart_pie",
    lte = "lte",
    route = "route",
    home_filled = "home_filled",
    manage_systems = "manage_systems",
    manage_services = "manage_services",
    support = "support",
    movie = "movie",
    warning_rounded = "warning_rounded",
    done = "done",
    cross_connects = "cross_connects",
    download2 = "download2",
    upload2 = "upload2",
    policy = "policy",
    find_in_page = "find_in_page",
    hexagon = "hexagon",
}
